.Container {
    margin: 50px auto;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Title {
    font-family: ModernEra-ExtraBold, sans-serif;
    font-size: 24px;
    text-align: center;
    padding: 0 14% 0 14%;
}

.Statement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 24px;
}

.TextStatement{
    margin: 10px;
    flex: 7;
    line-height: 1.5;
}

.ImageWrapper{
    width: 50px;
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContainerContent {
    display: flex;
    flex-direction: column;
}

@media (min-width: 767px) {
    .ContainerContent {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 70%;
    }
    .Container{
        max-width: 430px;
    }
    .Statement{
        margin: 20px 0;
    }
    .Title{
        font-size: 33px;
    }
}
