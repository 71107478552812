.banner {
  top: 0;
  position: sticky;
  width: 100%;
  height: 61px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #949CE3;
  transition: .3s ease-in-out;
  z-index: 1;
}

.banner-content {
  flex: 8;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
  font-weight: Bold;
  line-height: 24px;
  color: white;
  text-align: center;
}

.hide-banner {
  transform: translateY(-108px);
}

.banner .banner-content {
  padding: 0 34px;
  display: flex;
  flex-direction: row;
}

.banner img {
  display: none;
}

.banner .banner-title {
  font-family: "Poppins", "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
  line-height: 1.3125rem;
  font-size: 14px;
}

.banner .banner-content {
  display: block;
}

@media (min-width: 840px) {
  .banner {
    height: 52px;
  }
  .banner img {
    display: block;
  }
  .banner-title {
    font-size: 16px !important;
  }
}
