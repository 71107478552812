.sticker{
  position: absolute;
  width: 141px;
  z-index: 1;
  left: 17px;
  bottom: -34px;
}
.heroTextContainer{
  width: 100%;
}
.PageContainer {
  width: 100%;
  position: relative;
  min-height: 100vh;
}

.Content {
  padding-bottom: 87px;
}

.BtnContainer {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 16px;
  z-index: 2;
}

.whatsContainer{
  width: 100%;
  background-color: #ebf0ff;
  height: auto;
  display: flex;
  margin: 0 0 48px 0;
}
.whatsContainerChile{
  width: 100%;
  height: auto;
  display: flex;
  margin: 0 0 48px 0;
  display: flex;
  background: #ECEEFB;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  flex-direction: column;
}
.iconContainer{
  width: 25%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}
.iconContainerChile{
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  margin: 16px;
}

.iconContainerChile p{
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  padding: 0px 2em 0 1em;
  margin:0
  }

.iconWhats{
  margin: 15px;
}
.iconWhatsChile{
  margin: 3px 0 0 2em;
  width: 22.39px;
  height: 22.5px;
  display: flex;
  align-self: flex-start;
}


.OutlineButton{
  width: 180px;
  height: 40px;
  margin: 16px 0;
  border-radius: 10px;
  box-shadow: 0 3px 12px 0 rgba(255, 71, 126, 0.3);
  border: solid 2px #ff477e;
  background-color: #ffffff;
  color: #ff477e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-family: ModernEra-ExtraBold, sans-serif;
  text-decoration: none;
}

.OutlineButtonChile{
  max-width: 312px;
  height: 40px;
  margin: 16px auto 24px auto;
  border-radius: 8px;
  box-shadow: 0 3px 12px 0 rgba(255, 71, 126, 0.3);
  border: 1px solid #E61E4D;
  background-color: #ffffff;
  color: #ff477e;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: ModernEra-ExtraBold, sans-serif;
  text-decoration: none;
}

.lastNote{
  background-color: #fce1e4;
  width: 100%;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 90px;
}

.lastNoteCL{
  background: #ffff;
  width: 100%;
  height: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  border-top: solid 1px #BDBDBD;
}

.textLastNote{
  width: 80%;
  font-family: ModernEra, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.textLastNoteCL{
  width: 80%;
font-family: 'Inter';
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 16px;
/* or 133% */
text-align: center;
letter-spacing: 0.004em;
color: #757575;

}

.textWhatsContainer{
  width: 70%;
}
.textWhatsContainerChile{
  width: 70%;
}

.MaxContainerCTA {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-decoration: none;
}

.MaxContainer, .MaxContainerReferrals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BtnContainerContact {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 16px;
  z-index: 2;
  margin-top: 32px;
  margin-bottom: 32px;
}

.isSticky {
  box-shadow: none;
  background-color: transparent;
}

.BookText {
  display: none;
}

.ActionBtn, .ActionBtnContact {
  /* text */
  font-family: ModernEra, serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  /* btn */
  cursor: pointer;
  padding: 5%;
  background-color: #e61e4d;
  border: 0;
  border-radius: 8px;
  outline: none;
}

.AnimationContainer {
  position: relative;
  overflow: hidden;
  max-width: 272px;
  height: auto;
  display: inline-block;
  background: #e61e4d;
  text-decoration: none;
}

.Video{
  width: 100vw;
}

.AnimationContainer::after {
  animation: shine 3s ease-in-out  infinite;
  animation-fill-mode: forwards;
  content: "";
  position: absolute;
  top: -110%;
  left: -210%;
  width: 170%;
  height: 400%;
  opacity: 0;
  transform: rotate(20deg);
  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0.0) 100%
  );
}

.IframeYoutube {
  width: 100%;
  height: 15rem;
}

@keyframes shine {
  10% {
    opacity: 1;
    top: -210%;
    left: -40%;
    transition-property: left, top, opacity;
    transition-duration: 1s, 1s, 1s;
    transition-timing-function: ease;
  }
  100% {
    opacity: 0;
    top: -210%;
    left: -40%;
    transition-property: left, top, opacity;
  }
}

@media (min-width: 767px) and (max-width: 1298px) {
  .IframeYoutube {
    width: 80%;
    height: 19rem;
    margin-left: 43px;
    border-radius: 16px;
  }

  .OutlineButtonChile{
    max-width: 381px;
  }
}

@media (min-width: 1298px) {
  .IframeYoutube {
    width: 80%;
    height: 26rem;
    margin-left: 80px;
    border-radius: 16px;
  }
  .OutlineButtonChile{
    max-width: 381px;
  }
  
}




@media (min-width: 767px) {
  .sticker{
    left: 33px;
    bottom: -43px;
  }

  .heroTextContainer{
    width: 52%;
  }

  .ActionBtn, .ActionBtnContact {
    padding: 1.4% 1%;
  }

  .ActionBtn {
    line-height: 1.23;
  }

  .BookText {
    display: inline;
    font-family: QuincyCF, sans-serif;
    font-size: 34px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #1e1e1e;
  }

  .MaxContainer{
    flex-direction: row-reverse;
    padding-top: 72px;
  }

  .ContainerVideo {
    margin: 15px 15px 15px 6em;
    width: 35%;
    height: auto;
    padding-top: 7%;
    padding-bottom: 7%;
    background-color: rgb(250, 200, 213);
    border-radius: 16px;
    display: flex;
    justify-content: center;
  }

  .Video{
    width: 100%;
  }

  .MaxContainer, .MaxContainerCTA {
    max-width: 84%;
    margin: auto;
  }
  .whatsContainer{
    max-width: 574px;
    margin: 0 auto;
    border-radius: 10px;
  }
  .whatsContainerChile{
    max-width: 453px;
    margin: 0 auto;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 6em;
  }
  .textWhatsContainer{
    font-size: 20px;
    line-height: 24px;
    width: 72%;
  }
  .textWhatsContainerChile{
    font-size: 20px;
    line-height: 24px;
    width: 72%;
  }
  .iconContainer{
    width: 21%;
    align-items: center;
  }
  .iconContainerChile{
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-content: center;
    align-items: center;
  }
 
  .OutlineButton{
    margin: 16px 0 16px 70px;
  }
  .textLastNote{
    font-size: 20px;
    line-height: 16px;
    width: 90%;
    margin: 0 auto;
  }
  .textLastNoteCL{
    line-height: 16px;
    width: 66%;
    margin: 24px auto 0 auto;

  }
  .lastNote{
    height: 47px;
    max-width: 1000px;
    margin: 60px auto 90px auto;
  }
  .lastNoteCL{
    height: 47px;
    max-width: 1000px;
    margin: 60px auto 90px auto;
  }
}
@media (min-width: 950px) {
  .textLastNoteCL{
    width: 61%;

  }
}