.MainContainer {
  margin-top: 24px;
  padding: 0px 24px;
}

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 0 20px 0;
}

.Column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  align-items: center;
}

.DoubleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 5;
}

.DrPrado {
  width: 90px;
  margin: 16px 16px 0 0;
}

.DrText {
  font-family: ModernEra;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
  /* max-width: 243px; */
}

.PrimaryTextMoons, .SecondaryTextNeedMed {
  margin-top: 16px;
}

.PrimaryTextMoons, .SecondaryTextMoons, .SecondaryTextNeedMed {
  margin-bottom: 16px;
}

.PrimaryTextNoCand {
  margin-bottom: 8px;
  margin-top: 16px;
}

.SecondaryTextNoCand {
  margin-bottom: 16px;
}

.PrimaryTextMoonsAdv {
  max-width: none;
}

.LoaderContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 48px;
}

.BorderDrImg {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}

.DoctorDetails {
  margin: 4px 0;
  font-size: 12px;
}

.NameDoctor {
  margin: 2px 0;
  font-family: ModernEra;
  font-size: 20px;
  font-weight: 800;
}

.SubtitleDoctor {
  font-family: ModernEra;
  font-size: 16px;
  font-weight: normal;
  padding-top: 12px;
  color: rgba(0, 0, 0, 0.6);
}

@media (min-width: 767px) {
  .MainContainer {
    padding: 0px 12px;
  }

  .NameDoctor {
    margin-top: 12px;
  }

  .SubtitleDoctor {
    padding-top: 20px;
  }

  .PrimaryTextMoons, .PrimaryTextMoonsAdv, .PrimaryTextNoCand {
    max-width: 496px;
    font-size: 20px;
  }

  .PrimaryTextNoCand {
    margin-bottom: 16px;
  }

  .SecondaryTextMoons {
    max-width: none;
    font-size: 20px;
  }

  .SecondaryTextNoCand {
    max-width: 496px;
    font-size: 20px;
  }

  .SecondaryTextNeedMed {
    max-width: none;
    font-size: 20px;
    padding-right: 32px;
    margin-bottom: 0;
    margin-top: 0;
  }

  .BorderDrImg {
    border-width: 5px;
  }
}
